import { useState } from 'react';
import { useInvalidate } from '@pankod/refine-core';

import { useApiSdk } from 'ui-core';
import { UploadAttachmentMutation } from 'api-client/types';

export type UploadedCallbackParams = {
    result: UploadAttachmentMutation;
    invalidate: ReturnType<typeof useInvalidate>;
    orderId: string;
}
export type UploadedCallback = (params: UploadedCallbackParams) => void;

export function useFileUploader(props: { order: any, onUploaded?: UploadedCallback }) {
    const sdk = useApiSdk();
    const invalidate = useInvalidate();
    const [isUploading, setIsUploading] = useState(false);
    const [file, setFile] = useState<File>();

    const handleFileChange = async (files: FileList | File[] | null, type?: string) => {
        if (!(files && files.length > 0)) {
            return;
        }

        setIsUploading(true);
        setFile(files[0]);

        try {
            const result = await sdk.UploadAttachment({
                orderId: props.order.id,
                type: type,
                file: files[0],
            });

            if (typeof props.onUploaded === 'function') {
                props.onUploaded({
                    result,
                    invalidate,
                    orderId: props.order.id,
                });
            }
        } finally {
            setIsUploading(false);
        }
    };

    return {
        file,
        handleFileChange,
        isUploading,
        setIsUploading,
    };
}
