import { useEffect, useState } from 'react';
import {
    Text,
    HStack,
    InputGroup,
    Checkbox,
    InputLeftElement,
    Button,
    Select,
    Stack,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalOverlay,
} from '@pankod/refine-chakra-ui';

import { useApiSdk, US_STATES } from 'ui-core';
import { formatPrice } from '../../utilities';

import { useQuery } from '@tanstack/react-query';
import type {
    GetActiveOrganizationQuery,
    GetRtoPaymentEstimateQuery,
    RtoPaymentEstimateOptions,
} from 'api-client/types';

function useActiveOrganization() {
    const sdk = useApiSdk();

    const queryResult = useQuery<GetActiveOrganizationQuery['activeOrganization']>({
        queryKey: ['activeOrganization'],
        queryFn: async () => {
            const result = await sdk.GetActiveOrganization();
            return result.activeOrganization;
        },
    });

    return queryResult;
}

function usePaymentEstimate(options: RtoPaymentEstimateOptions) {
    const sdk = useApiSdk();

    const queryResult = useQuery<GetRtoPaymentEstimateQuery['rtoPaymentEstimate']>({
        queryKey: ['rtoPaymentEstimate', options],
        queryFn: async () => {
            const result = await sdk.GetRtoPaymentEstimate({ options });
            return result.rtoPaymentEstimate;
        },
        enabled: !!options.state && options.retailPrice > 0,
    });

    return queryResult;
}

export interface CalculatorModalProps {
    isOpen: boolean;
    onClose(): void;
}

export const CalculatorModal: React.FC<CalculatorModalProps> = (props) => {
    const [buildingTotal, setBuildingTotal] = useState(0.0);
    const [taxTotal, setTaxTotal] = useState(0.0);
    const [includeLDW, setIncludeLDW] = useState(false);
    const [enableGreaterPayment, setEnableGreaterPayment] = useState(false);
    const [greaterInitialPayment, setGreaterInitialPayment] = useState(0.0);
    const [targetState, setTargetState] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const activeOrganization = useActiveOrganization();

    const paymentEstimate = usePaymentEstimate({
        retailPrice: buildingTotal,
        taxRate: taxTotal,
        addLdw: includeLDW,
        state: targetState,
        terms: [],
        greaterInitialPayment: Number(enableGreaterPayment ? greaterInitialPayment || 0 : 0),
    });

    useEffect(() => {
        if (!targetState && activeOrganization.data?.address?.state) {
            setTargetState(activeOrganization.data?.address?.state);
        }
    }, [activeOrganization.data]);

    const onBuildingTotalChange = (event: any) => {
        try {
            const inputValue = event.target.value;
            if (inputValue || inputValue.length > 0) {
                const bTotal = parseFloat(inputValue);
                if (Number.isNaN(bTotal)) {
                    setErrorMessage('Invalid value for Retail Price');
                } else {
                    setBuildingTotal(bTotal);
                    setErrorMessage('');
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const onTaxTotalChange = (event: any) => {
        try {
            const inputValue = event.target.value;
            if (inputValue || inputValue.length > 0) {
                const taxTotal = parseFloat(event.target.value);
                if (Number.isNaN(taxTotal)) {
                    setErrorMessage('Invalid value for Tax Rate');
                } else {
                    setTaxTotal(taxTotal);
                    setErrorMessage('');
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    const onStateChange = (event: any) => {
        setTargetState(event.target.value);
    };

    const handleOnChangeLDW = () => {
        setIncludeLDW(!includeLDW);
    };

    const handleChangeEnableGreaterPayment = () => {
        setEnableGreaterPayment(!enableGreaterPayment);
    };

    const handleChangeGreaterPayment = (event: any) => {
        setGreaterInitialPayment(event.target.value);
    };

    const onCalcClick = () => {
        if (!buildingTotal || buildingTotal < 0.01) {
            setErrorMessage('Please enter a value for Retail Price');
        } else if (taxTotal > 0 && taxTotal < 1) {
            setErrorMessage('Please enter tax rate as a percentage');
        } else if (!targetState || targetState.length < 1) {
            setErrorMessage('Please select the target State');
        } else {
            setErrorMessage('');
            doCalcSubmit();
        }
    };

    const doCalcSubmit = () => {
        paymentEstimate.refetch();
    };

    const onCloseClick = () => {
        setBuildingTotal(0.0);
        setTaxTotal(0.0);
        setTargetState('');
        setErrorMessage('');
        props.onClose();
    };

    return (
        <Modal blockScrollOnMount={true} isOpen={props.isOpen} onClose={onCloseClick}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Calculator</ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <HStack w="100%" spacing={4}>
                        <FormControl flex="1">
                            <InputGroup>
                                <InputLeftElement
                                    pointerEvents="none"
                                    color="gray.300"
                                    fontSize="1.2em"
                                    children="$"
                                />
                                <Input
                                    placeholder="Retail Price"
                                    onChange={onBuildingTotalChange}
                                />
                            </InputGroup>
                        </FormControl>
                        <FormControl maxWidth="120px">
                            <InputGroup>
                                <InputLeftElement
                                    pointerEvents="none"
                                    color="gray.300"
                                    fontSize="1.2em"
                                    children="%"
                                />
                                <Input placeholder="Tax Rate" onChange={onTaxTotalChange} />
                            </InputGroup>
                        </FormControl>
                    </HStack>
                    <FormControl mt={2}>
                        <Checkbox
                            id="ldw"
                            colorScheme="green"
                            defaultChecked={includeLDW}
                            onChange={handleOnChangeLDW}
                        >
                            Include LDW
                        </Checkbox>
                    </FormControl>
                    <FormControl mt={2}>
                        <Checkbox
                            id="greater_payment"
                            colorScheme="green"
                            isChecked={enableGreaterPayment}
                            onChange={handleChangeEnableGreaterPayment}
                        >
                            Greater Initial Payment
                        </Checkbox>
                    </FormControl>
                    {enableGreaterPayment && (
                        <FormControl mt={2} width="100" maxWidth="264px">
                            <InputGroup>
                                <InputLeftElement
                                    pointerEvents="none"
                                    color="gray.300"
                                    fontSize="1.2em"
                                    children="$"
                                />
                                <Input
                                    placeholder="Greater Initial Payment"
                                    onChange={handleChangeGreaterPayment}
                                    value={greaterInitialPayment}
                                />
                            </InputGroup>
                        </FormControl>
                    )}
                    <FormControl mt={4}>
                        <Select
                            placeholder="State"
                            width={'auto'}
                            defaultValue={targetState}
                            onChange={onStateChange}
                        >
                            {Object.entries(US_STATES).map(([code, name]) => (
                                <option key={code} value={code}>
                                    {name}
                                </option>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl mt={4}>
                        <FormLabel>{errorMessage}</FormLabel>
                        <Stack>
                            {paymentEstimate.data &&
                                paymentEstimate.data.map((term) => (
                                    <Flex
                                        key={term.term}
                                        alignItems="center"
                                        justifyContent="space-between"
                                        fontSize="md"
                                        bgColor="gray.100"
                                        borderRadius="sm"
                                        px={4}
                                        py={2}
                                    >
                                        <Text>{term.term} months</Text>
                                        <Text fontWeight="bold">
                                            {formatPrice(term.recurringPayment)}
                                        </Text>
                                    </Flex>
                                ))}
                        </Stack>
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={onCalcClick} isLoading={paymentEstimate.isLoading && paymentEstimate.fetchStatus != 'idle'}>
                        Recalculate
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
