import { useRef } from 'react';
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Button,
    useDisclosure,
} from '@chakra-ui/react';

type ConfirmDeleteProps = {
    onConfirm: () => void;
    onCancel?: () => void;
};

export type ConfirmDeleteModalProps = {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    onCancel?: () => void;
    title: string;
    message?: string;
}

export function useConfirmDelete() {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return {
        props: {
            isOpen,
            onClose,
        },
        open: onOpen,
    };
}

export const ConfirmDelete = (props: ConfirmDeleteModalProps) => {
    const cancelRef = useRef<any>();

    const message = props.message || "Are you sure? You can't undo this action afterwards";

    const handleCancel = () => {
        props.onClose();
        props.onCancel && props.onCancel();
    };

    const handleConfirm = () => {
        props.onClose();
        props.onConfirm();
    };

    return (
        <AlertDialog isOpen={props.isOpen} leastDestructiveRef={cancelRef} onClose={props.onClose}>
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        {props.title}
                    </AlertDialogHeader>

                    <AlertDialogBody>{message}</AlertDialogBody>

                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={handleCancel} variant="ghost">
                            Cancel
                        </Button>
                        <Button colorScheme="red" onClick={handleConfirm} ml={3}>
                            Delete
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
};
