
import type { Column } from "@pankod/refine-react-table";

export interface ColumnButtonProps {
    column: Column<any, any>; // eslint-disable-line
}

export interface FilterElementProps {
    value: any;
    onChange: (value: any) => void;
}

export interface IChannelSelect {
    id: string;
    code: String;
}

export interface IUser{
    id: string;
    firstName: string;
    lastName: String;
    email: string;
    password: string;
    roleIds: String[];
}

export type TemplateVariable = {
    title: string,
    color: string,
    type: string,
    format: string,
    required?: boolean,
}

export function validateTemplateInputs(templateTitle: string, templateState: string, templateCompanies: string, templateContents: string, templateVariables: string) {
    let errorMessage = "";
    if(!templateTitle || templateTitle.length < 1) {
        errorMessage =  "Title is required";
    } else if(!templateState || templateState.length < 1) {
        errorMessage = "You must select at least one state";
    } else if(!templateCompanies || templateCompanies.length < 1) {
        errorMessage = "You must select at least one company";
    } else if(!templateContents || templateContents.length < 1) {
        errorMessage = "You have not entered any template content";
    } else if(!templateVariables || templateVariables.length < 1) {
        errorMessage = "You have not entered any template variables";
    } else {
        errorMessage = validateVariables(templateContents, templateVariables);
    }

    if(errorMessage.length > 0) {
        return errorMessage;
    } else {
        return undefined;
    }
}

export function validateVariables(content: string, variables: string){
    let contentObj = JSON.parse(content);
    let variableList: TemplateVariable[] = JSON.parse(variables) as TemplateVariable[];
    let varnameMap = new Map<string, string>();
    let newVars: TemplateVariable[] = [];
    
    //grab all the variables present in the template content
    contentObj?.root?.children?.forEach( (child: any) => {
        child.children?.forEach( (grandchild: any) => {
            let contentText = grandchild?.text as string;
            let index = 0;
            let endIndex = 0;

            if (!contentText) {
                return;
            }

            while(index > -1) {
                index = contentText.indexOf("{", endIndex);
                if(index > -1) {
                    endIndex = contentText.indexOf("}", index+1);
                    
                    let varName = contentText.substring(index+1, endIndex);
                    if(!varnameMap.get(varName)) {
                        varnameMap.set(varName,varName);

                        //let newVar = createVariable(varName);
                        //newVars.push(newVar);
                    } 

                }
            }
        })
        
    });

    //check for variables defined but not used in the template content
    let varErrorMessage = "";
    variableList.forEach( (variable) => {
        if(!varnameMap.get(variable.title)) {
            // in variable list but not template
            if(varErrorMessage.length < 1) {
                varErrorMessage = "The following variables are missing from the template content: " + variable.title;
            } else {
                varErrorMessage += ", " + variable.title;
            }
        } else {
            varnameMap.delete(variable.title);
        }
    }); 

    //check for variables in template content but not defined in the variable list
    const missingVars = varnameMap.keys();
    let templateErrorMessage = "";
    for(let missingTitle of missingVars) {
        if(templateErrorMessage.length < 1) {
            templateErrorMessage = "The following variables are missing from variable deinitions: " + missingTitle;
        } else {
            templateErrorMessage += ", " + missingTitle;
        }
        console.log("In template and not variables: " + missingTitle);
    }

    //format the error message
    let message = "";
    if(varErrorMessage?.length > 0 && templateErrorMessage?.length > 0 ) {
        message = varErrorMessage + ". " + templateErrorMessage;
    } else if(varErrorMessage?.length > 0){
        message = varErrorMessage;
    } else if(templateErrorMessage?.length > 0){
        message = templateErrorMessage;
    }

    return message;
} 

export function createVariable(varName: string) {
    let color = "green";
    let varType = "customer"
    let varFormat = "string";
    if(varName.indexOf("building") > -1) {
        color = "blue";
        varType = "building";
    } else if(varName.indexOf("initial") > -1 || varName.indexOf("billing") > -1 || varName.indexOf("tax") > -1 || varName.indexOf("ldw") > -1 || varName.indexOf("net") > -1) {
        color = "teal";
        varType = "terms";
        varFormat = "currency";
        
        
    } else if(varName.indexOf("signature") > -1) {
        color = "red";
        varType = "signature";
    } 

    if( varName.indexOf("date") > -1) {
        varFormat = "date"
    }

    let newVar: TemplateVariable = {
        title: varName,
        format: varFormat,
        type: varType,
        color: color,
    }

    return newVar;
}