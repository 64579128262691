import { useEffect, useState } from 'react';
import { useDropzone, Accept } from 'react-dropzone';

import { Box, Button, Flex, Icon, IconButton, Progress } from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import { IconFile } from '@tabler/icons';

import { useApiSdk } from 'ui-core';
import { useFileUploader, UploadedCallbackParams } from '../../hooks/use-file-uploader';
import { useInvalidateRtoOrderData } from '../../hooks/use-rto-order-form-data';

type PhotoIdUploadProps = {
    order: any;
    onChange?: (document: any) => void;
};

export const PhotoIdUpload = (props: PhotoIdUploadProps) => {
    const [isDragOver, setIsDragOver] = useState(false);
    const [document, setDocument] = useState<Record<string, any> | null>(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const sdk = useApiSdk();
    const invalidateOrderData = useInvalidateRtoOrderData();

    const deleteFile = async () => {
        if (!document) {
            return;
        }

        setIsDeleting(true);

        try {
            await sdk.DeleteAttachment({
                input: {
                    orderId: props.order.id,
                    documentId: document.id,
                },
            });
            setDocument(null);
            invalidateOrderData();
        } finally {
            setIsDeleting(false);
        }
    };

    useEffect(() => {
        if (props.order?.rtoContract?.documents?.length) {
            const licenseDoc = props.order.rtoContract.documents.find(
                (doc: any) => doc.type === 'drivers-license'
            );
            setDocument(licenseDoc && licenseDoc.status !== 'none' ? licenseDoc : null);
        }
    }, [props.order]);

    useEffect(() => {
        if (typeof props.onChange === 'function') {
            props.onChange(document);
        }
    }, [document]);

    const onUploaded = (params: UploadedCallbackParams) => {
        if (params.result.uploadAttachment?.rtoContract?.documents?.length) {
            const licenseDoc = params.result.uploadAttachment.rtoContract.documents.find(
                (doc: any) => doc.type === 'drivers-license'
            );
            if (licenseDoc && licenseDoc.status !== 'none') {
                setDocument(licenseDoc);
            }
            invalidateOrderData();
        }
    };

    const { file, handleFileChange, isUploading, setIsUploading } = useFileUploader({
        order: props.order,
        onUploaded,
    });

    let fileTypes: Accept = {
        'application/pdf': ['.pdf'],
        'image/jpeg': ['.jpg', '.jpeg'],
        'image/png': ['.png'],
    };
    let fileTypeLabel = 'PDF, JPEG, PNG';

    const dropzone = useDropzone({
        onDrop: (files: File[]) => {
            setIsDragOver(false);
            handleFileChange(files, 'drivers-license');
        },
        onDragEnter: (e: any) => {
            setIsDragOver(true);
        },
        onDragLeave: (e: any) => {
            setIsDragOver(false);
        },
        accept: fileTypes,
        noClick: true,
        noKeyboard: true,
        preventDropOnDocument: true,
    });

    if (document && document.status != 'none') {
        return (
            <Flex
                px={3}
                py={3}
                borderRadius="md"
                alignItems="center"
                bg="gray.50"
                border="1px"
                borderStyle="solid"
                borderColor="gray.100"
            >
                <Icon as={IconFile} mr={2} w={12} h={12} color="gray.300" />
                <Box flex="1">
                    <Flex flex="1" flexDirection="column" overflow="hidden">
                        <Box overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                            {document.name}
                            <Box fontSize="xs" color="gray.500">
                                {document.fileName}
                            </Box>
                        </Box>
                    </Flex>
                </Box>
                <Flex alignItems="center" justifyContent="right">
                    <Box ml={4}>
                        <IconButton
                            icon={<DeleteIcon />}
                            aria-label="Delete"
                            size="xs"
                            variant="ghost"
                            colorScheme="gray"
                            isLoading={isDeleting}
                            onClick={deleteFile}
                        />
                    </Box>
                </Flex>
            </Flex>
        );
    }

    return (
        <Flex
            px={3}
            py={3}
            minH="68px"
            borderRadius="md"
            alignItems="center"
            bg={isDragOver ? 'blue.50' : 'white'}
            border="2px"
            borderStyle="dashed"
            borderColor={isDragOver ? 'blue.500' : 'gray.200'}
            {...dropzone.getRootProps()}
        >
            {isUploading ? (
                <Box px={4} width="100%">
                    <Box fontSize="xs" mb={1}>
                        Uploading...
                    </Box>
                    <Progress size="xs" isIndeterminate />
                </Box>
            ) : (
                <>
                    <Box mr={4}>
                        <Button variant="outline" onClick={dropzone.open}>
                            Select File
                        </Button>
                        <input {...dropzone.getInputProps()} />
                    </Box>
                    <Box flex="1">
                        <Box fontSize="xs" color="gray.500">
                            Accepted file types: {fileTypeLabel}
                        </Box>
                    </Box>
                </>
            )}
        </Flex>
    );
};

export default PhotoIdUpload;
