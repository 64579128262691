import { useState } from 'react';
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Link,
    Progress,
    Select,
    VStack,
} from '@chakra-ui/react';

import { ActionBar, useApiSdk } from 'ui-core';
import { useRtoOrderFormData } from '../../../hooks/use-rto-order-form-data';
import { ContractFinalized } from '../contract-finalized';

const StatusIndicator = (props: { status: string; orderId?: string }) => {
    let message = 'Preparing documents, please wait...';
    if (props.status == 'opening') {
        message = 'Redirecting to SignNow...';
    }

    if (props.status == 'submitted' && props.orderId) {
        return <ContractFinalized orderId={props.orderId} message="Contract Submitted" />;
    }

    return (
        <Box textAlign="center" width="100%">
            <Box fontSize="md">{message}</Box>
            <Progress size="lg" isIndeterminate mt={4} />
        </Box>
    );
};

export const StepFinalize = () => {
    const pageQuery = useRtoOrderFormData();
    const sdk = useApiSdk();
    const [status, setStatus] = useState('');
    const [signatureMethod, setSignatureMethod] = useState('');

    const orderId = pageQuery.data?.order?.id || '';
    const pdfPreviewUrl = pageQuery.data?.rtoConfig?.pdfPreviewUrl + orderId;

    const handleClickContinue = async () => {
        setStatus('loading');

        try {
            const result = await sdk.FinalizeContract({
                input: {
                    orderId,
                    signatureMethod,
                },
            });

            if (result.finalizeContract.redirectUrl) {
                setStatus('opening');

                window.location.assign(result.finalizeContract.redirectUrl);
            } else {
                setStatus('submitted');
            }
        } catch (err) {
            setStatus('');
            throw err;
        }
    };

    return (
        <Box>
            <VStack width="100%" alignItems="start" spacing={10} color="gray.700">
                {status ? (
                    <StatusIndicator status={status} orderId={orderId} />
                ) : (
                    <>
                        <Box>
                            <FormControl>
                                <FormLabel>Signature method</FormLabel>
                                <Select
                                    value={signatureMethod}
                                    onChange={(e) => setSignatureMethod(e.target.value)}
                                >
                                    <option value="">Select method</option>
                                    <option value="email">Request signature via email</option>
                                    <option value="text">Request signature via text</option>
                                    <option value="embedded">Sign on this device</option>
                                    <option value="upload">Upload signed document</option>
                                </Select>
                            </FormControl>
                        </Box>
                    </>
                )}
                {status != 'submitted' && (
                    <ActionBar
                        submitLabel="Submit"
                        onSubmit={handleClickContinue}
                        isDisabled={!signatureMethod}
                    >
                        {({ backButton, continueButton }: any) => (
                            <>
                                {backButton}
                                <Box>
                                    {pdfPreviewUrl && (
                                        <Link href={pdfPreviewUrl} target="_blank">
                                            <Button variant="outline" mr={4}>
                                                Preview
                                            </Button>
                                        </Link>
                                    )}
                                    {continueButton}
                                </Box>
                            </>
                        )}
                    </ActionBar>
                )}
            </VStack>
        </Box>
    );
};

export default StepFinalize;
