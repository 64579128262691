import { MutableRefObject, useEffect, useState } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
    Checkbox,
    Flex,
    IconButton,
    Input,
    Stack,
    Switch,
} from '@chakra-ui/react';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';

import { ContractTerm, OrderPriceTier } from 'api-client/types';

export type RtoSettingsFormData = {
    terms: ContractTerm[];
    ldw: OrderPriceTier[];
    craOption: boolean;
    useDefaultSettings?: boolean;
};

export type RtoSettingsFormProps = {
    dataRef?: MutableRefObject<RtoSettingsFormData>;
    initialState?: any;
    defaultSettings?: any;
};

const InitialPaymentBracket = (props: any) => {
    const handleChange = (prop: string, value: any) => {
        if (typeof props.onChange == 'function') {
            props.onChange(props.id, prop, value);
        }
    };

    return (
        <Flex alignItems="end">
            <Input
                type="number"
                value={props.value || ''}
                onChange={(e) => handleChange('value', e.target.value)}
                mr={2}
                maxWidth="90px"
                disabled={props.disabled}
            />
            <Box>
                <Box fontSize="sm">From</Box>
                <Input
                    type="number"
                    value={props.from || ''}
                    onChange={(e) => handleChange('from', e.target.value)}
                    mr={2}
                    size="sm"
                    height="1.5rem"
                    maxWidth="90px"
                    disabled={props.disabled}
                />
            </Box>
            <Box>
                <Box fontSize="sm">To</Box>
                <Input
                    type="number"
                    value={props.to || ''}
                    onChange={(e) => handleChange('to', e.target.value)}
                    size="sm"
                    height="1.5rem"
                    maxWidth="90px"
                    disabled={props.disabled}
                />
            </Box>
        </Flex>
    );
};

function prepareInitialPaymentState(values: any) {
    const valuesWithId: any[] = [];

    for (let i = 0; i < values.length; i++) {
        valuesWithId.push({ id: i, ...values[i] });
    }

    return valuesWithId;
}

const InitialPaymentSetting = (props: any) => {
    const [values, setValues] = useState(prepareInitialPaymentState(props.termData.initialPayment));

    const syncTermState = (newValues: any) => {
        const termState = props.terms.find((t: any) => (t.length == props.termData.length));
        termState.initialPayment = newValues.map((v: any) => {
            // extract id because it is only used for local UI state
            const { id, ...rest } = v;
            return rest;
        });

        props.setTerms([...props.terms]);
    };

    const handleChange = (id: number, prop: string, value: number) => {
        const valueState = values.find((v) => v.id == id);
        valueState[prop] = value;
        const newValues = [...values];

        setValues(newValues);
        syncTermState(newValues);
    };

    const handleAddRow = () => {
        let maxId = 0;
        for (let value of values) {
            if (value.id > maxId) {
                maxId = value.id;
            }
        }
        setValues([...values, { id: maxId + 1, value: 0, from: 0, to: 0 }]);
    };

    const handleRemoveRow = (id: number) => {
        const newValues = values.filter((v) => v.id != id);
        setValues(newValues);
        syncTermState(newValues);
    };

    return (
        <Stack>
            {values.map((value, index) => (
                <Flex alignItems="center" key={value.id}>
                    {values.length == 1 || index == values.length - 1 ? (
                        <IconButton
                            variant="ghost"
                            size="sm"
                            icon={<AddIcon />}
                            aria-label="add row"
                            onClick={handleAddRow}
                            mt={1.5}
                            mr={2}
                        />
                    ) : (
                        <IconButton
                            variant="ghost"
                            size="sm"
                            icon={<MinusIcon />}
                            aria-label="add row"
                            onClick={() => handleRemoveRow(value.id)}
                            mt={1.5}
                            mr={2}
                        />
                    )}
                    <InitialPaymentBracket
                        {...value}
                        onChange={handleChange}
                        disabled={props.disabled}
                    />
                </Flex>
            ))}
        </Stack>
    );
};

const TermSettings = (props: any) => {
    const terms = props.terms;
    const termData = props.termData;
    const useDefaultSettings = props.useDefaultSettings;
    const [isEnabled, setIsEnabled] = useState(props.enabled ?? true);

    const handleChangeDivisor = (e: any) => {
        const termState = terms.find((term: any) => term.length == termData.length);
        termState.divisor = Number(e.target.value);

        props.setTerms([...terms]);
    };

    const handleChangeEpo = (e: any) => {
        const termState = terms.find((term: any) => term.length == termData.length);
        termState.earlyPurchasePercentage = Number(e.target.value);

        props.setTerms([...terms]);
    };

    const handleChangeEnabled = (e: any) => {
        setIsEnabled(e.target.checked);
        let newTerms = [...terms];
        const existingTerm = newTerms.find((term: any) => term.length == termData.length);

        if (existingTerm) {
            existingTerm.enabled = e.target.checked;
        } else {
            newTerms.push({ ...termData, enabled: e.target.checked });
            newTerms.sort((a, b) => a.length - b.length);
        }

        props.setTerms(newTerms);
    };

    const labelStyle: any = {
        px: 4,
        fontWeight: 'medium',
    };

    if (useDefaultSettings) {
        labelStyle.color = 'gray.400';
    }

    return (
        <AccordionItem border="none" mb={2}>
            <AccordionButton backgroundColor="gray.100" borderRadius="md">
                <Checkbox
                    isChecked={isEnabled}
                    onChange={handleChangeEnabled}
                    mr={2}
                    disabled={useDefaultSettings}
                />
                <Box flex="1" textAlign="left">
                    {termData.length} month term
                </Box>
                <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4} px={0}>
                <Flex py={2} alignItems="center" justifyContent="space-between">
                    <Box {...labelStyle}>Divisor</Box>
                    <Input
                        type="number"
                        value={termData.divisor}
                        onChange={handleChangeDivisor}
                        maxWidth="160px"
                        disabled={useDefaultSettings || !isEnabled}
                    />
                </Flex>
                <Flex py={2} alignItems="center" justifyContent="space-between">
                    <Box {...labelStyle}>Initial payment</Box>
                    <InitialPaymentSetting {...props} disabled={useDefaultSettings || !isEnabled} />
                </Flex>
                <Flex py={2} alignItems="center" justifyContent="space-between">
                    <Box {...labelStyle}>Early purchase percentage</Box>
                    <Input
                        type="number"
                        value={termData.earlyPurchasePercentage}
                        onChange={handleChangeEpo}
                        maxWidth="160px"
                        disabled={useDefaultSettings || !isEnabled}
                    />
                </Flex>
            </AccordionPanel>
        </AccordionItem>
    );
};

const CreditReserveAccountSettings = (props: any) => {
    const defaultOptions = { disabled: false };
    const [craOptions, setCraOptions] = useState(
        props.initialState?.greaterPaymentOptions || defaultOptions
    );

    useEffect(() => {
        // sync data with parent
        if (props.dataRef?.current) {
            props.dataRef.current.greaterPaymentOptions = craOptions;
        }
    }, [craOptions]);

    const handleChangeDisabled = (e: any) => {
        setCraOptions({ ...craOptions, disabled: !e.target.checked });
    };

    const labelStyle: any = {
        px: 4,
        fontWeight: 'medium',
    };

    if (props.useDefaultSettings) {
        labelStyle.color = 'gray.400';
    }

    return (
        <Flex py={2} alignItems="center" justifyContent="space-between">
            <Box {...labelStyle}>Enable greater initial payment</Box>
            <Switch
                isChecked={!craOptions.disabled}
                onChange={handleChangeDisabled}
                disabled={props.useDefaultSettings}
            />
        </Flex>
    );
};

export const RtoSettingsForm = (props: RtoSettingsFormProps) => {
    const [terms, setTerms] = useState<ContractTerm[]>(props.initialState?.terms || []);
    const [useDefaultSettings, setUseDefaultSettings] = useState(
        (props.initialState?.scope || 'GLOBAL') === 'GLOBAL'
    );

    const defaultTerms: ContractTerm[] =
        props.defaultSettings?.terms || props.initialState?.terms || [];

    useEffect(() => {
        if (props.initialState?.terms) {
            setTerms(props.initialState?.terms);

            if (props.dataRef) {
                props.dataRef.current = props.initialState;
            }
        }
    }, [props.initialState]);

    useEffect(() => {
        // sync data with parent
        if (props.dataRef?.current) {
            props.dataRef.current.terms = terms;
            props.dataRef.current.useDefaultSettings = useDefaultSettings;
        }
    }, [terms, useDefaultSettings]);

    const handleChangeUseDefault = (e: any) => {
        setUseDefaultSettings(e.target.checked);
    };

    return (
        <Box pb={2}>
            <Box mb={4}>
                <Checkbox isChecked={useDefaultSettings} onChange={handleChangeUseDefault}>
                    Use default settings
                </Checkbox>
            </Box>
            <Accordion allowToggle mb={4}>
                {defaultTerms.map((defaultTermData) => {
                    let termData = terms.find((t) => t.length == defaultTermData.length);
                    let enabled = termData?.enabled !== false;
                    if (!termData) {
                        termData = defaultTermData;
                        enabled = false;
                    }

                    return (
                        <TermSettings
                            key={termData.length}
                            terms={terms}
                            setTerms={setTerms}
                            termData={termData}
                            useDefaultSettings={useDefaultSettings}
                            enabled={enabled}
                        />
                    );
                })}
            </Accordion>
            <Box borderTop="1px" borderBottom="1px" borderColor="gray.200">
                <CreditReserveAccountSettings {...props} useDefaultSettings={useDefaultSettings} />
            </Box>
        </Box>
    );
};
