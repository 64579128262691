import { createContext, useContext, useEffect, useMemo, useId, useRef, useState, ReactNode } from 'react';
import { useDropzone, Accept } from 'react-dropzone';

import {
    Box,
    Button,
    Card,
    CardBody,
    CardHeader,
    Checkbox,
    Flex,
    Heading,
    Icon,
    IconButton,
    Input,
    Link,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Modal,
    ModalProps,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    PopoverArrow,
    PopoverCloseButton,
    PopoverAnchor,
    Progress,
    Tag,
    TagLabel,
    Table,
    Tbody,
    Thead,
    Td,
    Th,
    Tr,
    Skeleton,
    Stack,
    useDisclosure,
} from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import {
    IconDownload,
    IconCheck,
    IconChevronDown,
    IconFile,
    IconFolder,
    IconUpload,
    IconWritingSign,
    IconDotsVertical,
} from '@tabler/icons';
import { useInvalidate, useNotification } from '@pankod/refine-core';

import { useApiSdk, useAppModeData, useConfirmDelete, ConfirmDelete } from 'ui-core';
import { useFileUploader, UploadedCallbackParams } from '../../hooks/use-file-uploader';

export type DocumentsBoxProps = {
    order: any;
};

type SignerMenuProps = {
    signers: any[];
    appMode: ReturnType<typeof useAppModeData>['mode'];
    menuTrigger: ReactNode;
};

declare const appConfig: any;

type MenuAction = {
    type: string;
    label: string;
    link?: string;
};

type ActionMenuProps = {
    document: any;
    callbacks: Record<string, (doc: any) => void | Promise<void>>;
};

type DocumentsContext = {
    order: any;
    documentList: any[];
    setDocumentList: (docs: any[]) => void;
    deleteDocument: (document: any) => Promise<any>;
};

const documentsContext = createContext<DocumentsContext | null>(null);
const DocumentsContextProvider = documentsContext.Provider;

const ActionMenu = (props: ActionMenuProps) => {
    const [isLoading, setIsLoading] = useState(false);
    const document = props.document;
    let actions: MenuAction[] = [];

    if (!document.locked) {
        if (document.viewLink && document.requiresAccessToken) {
            actions.push({
                type: 'view',
                label: 'View',
            });
        } else if (document.viewLink) {
            actions.push({
                type: 'view',
                label: 'View',
                link: document.viewLink,
            });
        }

        if (document.signatureRequired) {
            actions.push({
                type: 'upload',
                label: 'Upload signed copy',
            });
        } else if (document.status == 'none') {
            actions.push({
                type: 'upload',
                label: 'Upload',
            });
        }

        if (!document.status) {
            actions.push({
                type: 'delete',
                label: 'Delete',
            });
        }
    }

    const processAction = async (action: any) => {
        if (props.callbacks[action.type]) {
            setIsLoading(true);
            try {
                await props.callbacks[action.type](document);
            } finally {
                setIsLoading(false);
            }
        }
    };

    return (
        <Menu>
            <MenuButton
                as={IconButton}
                aria-label="Action menu"
                icon={<Icon as={IconDotsVertical} w="16px" h="16px" />}
                variant="outline"
                size="sm"
                isLoading={isLoading}
            />
            <MenuList>
                {actions.map((action: any) => (
                    <MenuItem
                        key={action.type}
                        {...(action.link
                            ? { as: 'a', href: action.link, target: '_blank' }
                            : { as: 'button', onClick: (e) => processAction(action) })}
                    >
                        {action.label}
                    </MenuItem>
                ))}
            </MenuList>
        </Menu>
    );
};

const SignerMenu = (props: SignerMenuProps) => {
    return (
        <Popover>
            <PopoverTrigger>
                {props.menuTrigger}
            </PopoverTrigger>
            <PopoverContent>
                <PopoverArrow />
                <PopoverBody fontSize="xs">
                    {props.signers.map((signer: any, index) => {
                        let itemContent: ReactNode = signer.status == 'fulfilled' ? 'Signed' : 'Pending';
                        if (signer.sign_link && signer.role == 'Owner' && props.appMode == 'superAdmin') {
                            itemContent = (
                                <Button as={Link} size="xs" href={signer.sign_link} variant="solid" mt={2}>
                                    Start signing
                                </Button>
                            );
                        }
                        return (
                            <Box
                                key={signer.id}
                                px={4}
                                py={3}
                                {...(index > 0 ? { borderTop: '1px', borderTopColor: 'gray.200' } : {})}
                            >
                                <Box fontWeight="bold">
                                    Signer: {signer.role == 'Owner' ? 'lessor' : signer.singer_phone_invite || signer.email}
                                </Box>
                                {itemContent}
                            </Box>
                        );
                    })}
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
};

function onFileUploaded(params: UploadedCallbackParams) {
    if (params.result.uploadAttachment && 'id' in params.result.uploadAttachment) {
        // reload order to update documents view
        params.invalidate({
            resource: 'orders',
            invalidates: ['detail'],
            id: params.orderId,
        });
    }
}

const FileUploader = (props: DocumentsBoxProps) => {
    const { handleFileChange, isUploading } = useFileUploader({ order: props.order, onUploaded: onFileUploaded });
    const [type, setType] = useState('other');
    const selectedTypeRef = useRef(type);

    let fileTypes: Accept = {
        'application/pdf': ['.pdf'],
        'image/jpeg': ['.jpg', '.jpeg'],
        'image/png': ['.png'],
    };

    const dropzone = useDropzone({
        onDrop: (files: File[]) => {
            handleFileChange(files, selectedTypeRef.current);
        },
        accept: fileTypes,
        noClick: true,
        noKeyboard: true,
        preventDropOnDocument: true,
    });

    const handleClick = (selectedType: string) => {
        setType(selectedType);
        selectedTypeRef.current = selectedType;
        dropzone.open();
    };

    const allTypes = props.order.rtoContract?.documentTypes || [];

    return (
        <>
            <Menu>
                <MenuButton
                    as={Button}
                    variant="outline"
                    leftIcon={<IconUpload />}
                    isLoading={isUploading}
                >
                    Upload
                </MenuButton>
                <MenuList>
                    {allTypes.map((typeMeta: any) => (
                        <MenuItem
                            key={typeMeta.type}
                            onClick={() => handleClick(typeMeta.type)}
                        >
                            {typeMeta.label}
                        </MenuItem>
                    ))}
                </MenuList>
            </Menu>
            <input {...dropzone.getInputProps()} />
        </>
    );
};

type UploadModal = Omit<ModalProps, 'children'> & {
    document: any;
    order: any;
};

const UploadModal = (props: UploadModal) => {
    const inputId = useId();
    const [isDragOver, setIsDragOver] = useState(false);
    const { file, handleFileChange, isUploading, setIsUploading } = useFileUploader({
        order: props.order,
        onUploaded: onFileUploaded,
    });

    let fileTypes: Accept = {
        'application/pdf': ['.pdf'],
        'image/jpeg': ['.jpg', '.jpeg'],
        'image/png': ['.png'],
    };
    let fileTypeLabel = 'PDF, JPEG, PNG';

    if (props.document.type.includes('contract') || props.document.type == 'order-form') {
        fileTypes = {
            'application/pdf': ['.pdf'],
        };
        fileTypeLabel = 'PDF';
    }

    const dropzone = useDropzone({
        onDrop: (files: File[]) => {
            setIsDragOver(false);
            handleFileChange(files, props.document.type);
        },
        onDragEnter: (e: any) => {
            setIsDragOver(true);
        },
        onDragLeave: (e: any) => {
            setIsDragOver(false);
        },
        accept: fileTypes,
        noClick: true,
        noKeyboard: true,
        preventDropOnDocument: true,
    });

    return (
        <Modal isOpen={props.isOpen} onClose={props.onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Upload {props.document.name}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {isUploading ? (
                        <Box py={8}>
                            <Box mb={4}>{file?.name || ''}</Box>
                            <Progress size="xs" isIndeterminate />
                        </Box>
                    ) : (
                        <Box
                            display="block"
                            borderRadius="md"
                            bg={isDragOver ? 'blue.50' : 'gray.50'}
                            border="2px"
                            borderStyle="dashed"
                            borderColor={isDragOver ? 'blue.500' : 'gray.200'}
                            textAlign={'center'}
                            p={8}
                            {...dropzone.getRootProps()}
                        >
                            <Box color="gray.600">Drag and drop file here</Box>
                            <Box pt={1} pb={2} color="gray.600" fontSize="sm">
                                or
                            </Box>
                            <Button variant="outline" onClick={dropzone.open}>
                                Select File
                            </Button>
                            <Box pt={4} color="gray.500" fontSize="sm">
                                Accepted file types: {fileTypeLabel}
                            </Box>
                            <input {...dropzone.getInputProps()} />
                        </Box>
                    )}
                </ModalBody>
                <ModalFooter />
            </ModalContent>
        </Modal>
    );
};

const DocumentItemLoading = (props: { file?: File }) => {
    return (
        <Skeleton borderRadius="md">
            <Flex
                px={3}
                py={3}
                borderRadius="md"
                alignItems="center"
                bg="gray.50"
                border="1px"
                borderColor="gray.100"
            >
                <Box mr={2} w={12} h={12} />
                <Flex flex="1" flexDirection="column" overflow="hidden">
                    <Box overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap" mb={1}>
                        {props.file?.name || ''}
                    </Box>
                </Flex>
            </Flex>
        </Skeleton>
    );
};

type BundleIncludesProps = {
    document: {
        id: string;
        includes: string[];
    };
    allTypes: Record<string, string>[];
};

const BundleIncludes = (props: BundleIncludesProps) => {
    const [includesState, setIncludesState] = useState(props.document.includes);
    const context = useContext(documentsContext);
    const sdk = useApiSdk();

    useEffect(() => {
        setIncludesState(props.document.includes);
    }, [props.document.includes]);

    useEffect(() => {
        if (context) {
            context.setDocumentList(
                context.order.rtoContract.documents.filter(
                    (d: any) => !includesState.includes(d.type)
                )
            );
            sdk.updateDocumentMetaData({
                input: {
                    orderId: context.order.id,
                    documentId: props.document.id,
                    includes: includesState,
                },
            });
        }
    }, [includesState]);

    const handleToggleInclude = (e: any) => {
        if (e.target.checked) {
            setIncludesState([...includesState, e.target.value]);
        } else {
            setIncludesState(includesState.filter((t) => t != e.target.value));
        }
    };

    return (
        <Flex mt={1} flexWrap="wrap" gridColumnGap={2} gridRowGap={2}>
            <Popover>
                <PopoverTrigger>
                    <Button
                        rightIcon={<IconChevronDown size="16px" />}
                        variant="outline"
                        colorScheme="gray"
                        size="xs"
                    >
                        Includes
                    </Button>
                </PopoverTrigger>
                <PopoverContent width="15rem">
                    <PopoverArrow />
                    <PopoverBody>
                        <Stack>
                            {props.allTypes
                                .filter((t) => t.type != 'contract')
                                .map((typeMeta) => (
                                    <Checkbox
                                        key={typeMeta.type}
                                        value={typeMeta.type}
                                        isChecked={includesState.includes(typeMeta.type)}
                                        onChange={handleToggleInclude}
                                    >
                                        {typeMeta.label}
                                    </Checkbox>
                                ))}
                        </Stack>
                    </PopoverBody>
                </PopoverContent>
            </Popover>
            {includesState.map((tag) => (
                <Tag key={tag} size="sm" variant="subtle" colorScheme="cyan" borderRadius="full">
                    <TagLabel>{tag}</TagLabel>
                </Tag>
            ))}
        </Flex>
    );
};

const DocumentItemStatus = (props: { document: any, appMode: SignerMenuProps['appMode'] }) => {
    const doc = props.document;

    if (!(doc.status == 'signed' || doc.status == 'customer_signed' || doc.status == 'unsigned')) {
        return null;
    }

    let statusLabel = doc.status.replace(/[_]/g, ' ');
    const hasSigners = doc.signers && doc.signers.length > 0;
    const tagStyle = {
        bg: 'white',
        borderRadius: 'full',
        fontWeight: 'normal',
    };

    if (hasSigners) {
        return (
            <SignerMenu
                signers={doc.signers}
                appMode={props.appMode}
                menuTrigger={
                    <Button
                        variant="outline"
                        size="xs"
                        colorScheme="gray"
                        leftIcon={doc.status != 'unsigned' ? <CheckCircleIcon boxSize="12px" color="green.500" /> : <></>}
                        rightIcon={hasSigners ? <Icon as={IconChevronDown} w="16px" h="16px" color="gray.500" /> : <></>}
                        {...tagStyle}
                    >
                        {statusLabel}
                    </Button>
                }
            />
        );
    }

    return (
        <Flex border="1px" borderColor="gray.200" alignItems="center" py={0.5} px={1.5} {...tagStyle}>
            <Box fontSize="xs" pl={1} pr={1}>{statusLabel}</Box>
        </Flex>
    );
}

const DocumentItem = (props: any) => {
    const context = useContext(documentsContext);
    const uploadDialogState = useDisclosure();
    const [isDeleting, setIsDeleting] = useState(false);
    const deleteController = useConfirmDelete();
    const sdk = useApiSdk();

    const doc = props.document;
    const isEmpty = (doc.status == 'none' || doc.status == 'draft') && !doc.downloadLink;

    let itemStyle = {
        bg: 'gray.50',
        border: '1px',
        borderStyle: 'solid',
        borderColor: 'gray.100',
    };

    if (isEmpty) {
        itemStyle = {
            bg: 'white',
            border: '2px',
            borderStyle: 'dashed',
            borderColor: 'gray.200',
        };
    }

    const actionCallbacks = {
        upload: () => {
            uploadDialogState.onOpen();
        },
        delete: () => {
            deleteController.open();
        },
        view: async (doc: any) => {
            const result = await sdk.GrantDocumentAccess({ orderId: props.order.id, docType: doc.type });
            if (result.grantDocumentAccess.token) {
                window.open(doc.viewLink + '?token=' + result.grantDocumentAccess.token, '_blank');
            }
        },
    };

    const handleConfirmDelete = () => {
        setIsDeleting(true);
        context?.deleteDocument(doc);
    };

    if (isDeleting) {
        return <DocumentItemLoading />;
    }

    return (
        <>
            <Flex px={3} py={3} borderRadius="md" alignItems="center" {...itemStyle}>
                <Icon
                    as={doc.type.includes('bundle') ? IconFolder : IconFile}
                    mr={2}
                    w={12}
                    h={12}
                    color="gray.300"
                />
                <Box flex="1">
                    <Flex flex="1" flexDirection="column" overflow="hidden">
                        <Box overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                            {doc.name}
                        </Box>
                        {doc.includes && (
                            <BundleIncludes
                                document={doc}
                                allTypes={props.order.rtoContract?.documentTypes || []}
                            />
                        )}
                    </Flex>
                </Box>
                <Flex alignItems="center" justifyContent="right">
                    <DocumentItemStatus document={doc} appMode={props.appMode} />
                    <Box ml={4}>
                        <ActionMenu document={doc} callbacks={actionCallbacks} />
                    </Box>
                </Flex>
            </Flex>
            <UploadModal
                isOpen={uploadDialogState.isOpen}
                onClose={uploadDialogState.onClose}
                order={props.order}
                document={doc}
            />
            <ConfirmDelete
                title="Delete Document"
                onConfirm={handleConfirmDelete}
                {...deleteController.props}
            />
        </>
    );
};

const DocumentsBoxMenu = (props: any) => {
    return (
        <Menu>
            <MenuButton
                as={Button}
                rightIcon={<Icon as={IconChevronDown} size="16px" />}
                variant="outline"
                size="sm"
            >
                Actions
            </MenuButton>
            <MenuList>
                <MenuItem
                    as="a"
                    href={`${appConfig.apiBaseUrl}/document/merged/${props.order.id}`}
                    download
                >
                    Download Merged Document
                </MenuItem>
                <MenuItem
                    as="a"
                    href={`${appConfig.apiBaseUrl}/document/rtopro/${props.order.id}`}
                    download
                >
                    Download RTO Pro File
                </MenuItem>
            </MenuList>
        </Menu>
    );
};

export const DocumentsBox = (props: DocumentsBoxProps) => {
    const appModeData = useAppModeData();
    const sdk = useApiSdk();
    const invalidate = useInvalidate();
    const [documentList, setDocumentList] = useState(props.order?.rtoContract?.documents || []);
    const orderId = props.order?.id;

    const deleteDocument = async (document: any) => {
        await sdk.DeleteAttachment({
            input: {
                orderId: orderId,
                documentId: document.id,
            },
        });
        invalidate({
            resource: 'orders',
            invalidates: ['detail'],
            id: orderId,
        });
    };

    const context = useMemo(
        () => ({
            order: props.order,
            documentList,
            setDocumentList,
            deleteDocument,
        }),
        [documentList, setDocumentList, orderId]
    );

    useEffect(() => {
        if (props.order?.rtoContract?.documents) {
            setDocumentList(props.order?.rtoContract?.documents);
        }
    }, [props.order?.rtoContract?.documents]);

    if (!props.order) {
        return null;
    }

    return (
        <DocumentsContextProvider value={context}>
            <Card>
                <CardHeader display="flex" justifyContent="space-between" alignItems="center">
                    <Heading size="md">Documents</Heading>
                    <DocumentsBoxMenu order={props.order} />
                </CardHeader>
                <CardBody>
                    <Stack>
                        {documentList.map((doc: any) => (
                            <DocumentItem
                                key={doc.id}
                                document={doc}
                                appMode={appModeData.mode}
                                order={props.order}
                            />
                        ))}
                    </Stack>
                    <Flex mt={5} justifyContent="space-between">
                        <FileUploader order={props.order} />
                    </Flex>
                </CardBody>
            </Card>
        </DocumentsContextProvider>
    );
};
